/**
 * MS-营销中心接口
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/marketing`; //服务前缀
/**
 * 营销活动信息
 */
// 【market026】根据会员号和状态码获取优惠券集合 - 马鹏浩
export function marketingCouponList(data) {
  return http.get(`${prefix}/marketing/coupon/list`, data);
}

// 小程序会员领券数量 优惠券记录 (统计总数)  http://192.168.1.50:40001/project/55/interface/api/3950
export function findMiniMemberCouponsCount(data) {
  return http.get(`${prefix}/find/mini/member/coupons/count`, data);
}

//窗口查询可用优惠券-许家炜
export const windowCouponActivityList = (data) => {
  return http.post(`${prefix}/window/coupon/activity/list`, data);
};

//【market112】窗口兑换优惠券
export function windowRedeemCoupons(data) {
  return http.post(`${prefix}/window/redeem/coupons`, data);
}
