/**
 * 新下单打印和支付成功方法
 * */
import { speak, printTicket, printSmallTicket } from "../utils/hardware";
import {
  // orderCateringPrint,
  orderQuickCombinationPrint,
  orderQuickTicketPrint,
  orderReissueticketprint,
} from "../api/search";
import { orderPrint, orderQuickretailprint } from "../api/order";

export default {
  methods: {
    // 支付成功
    payOk(orderId, noPrint = false, pageType = "") {
      // 语音播报
      speak("支付成功");
      // 如果挂账，则不需要打印功能
      if (!noPrint) {
        // types.forEach((item) => {
        //   // 餐饮下的单，调用云打印机 餐饮单品-----1435059066526212100  // 餐饮套餐-----1435059066526212101
        //   if (
        //     item === "1435059066526212100" ||
        //     item === "1435059066526212101"
        //   ) {
        //     orderCateringPrint({ orderId })
        //       .then(() => {})
        //       .catch(() => {});
        //   } else {
        //     // 非餐饮的订单，调本地打印机
        //     this.printProof(item, orderId);
        //   }
        // });
        if (pageType === "replace") {
          this.replacePrint(orderId);
        } else {
          this.printProof(orderId);
        }
      }
    },
    // 补办打印
    replacePrint(orderId) {
      orderReissueticketprint({
        orderId: orderId,
      })
        .then((res) => {
          if (res) {
            let flag = printTicket(JSON.stringify(res));
            //如果成功调用打印门票，就掉接口通知后端已经取票
            if (flag) {
              orderPrint({
                channel: 7, //6是窗口端 手持机7
                orderId: orderId,
              })
                .then(() => {})
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },
    // 打印凭证
    printProof(orderId) {
      // let Hardware = localStorage.getItem("Hardware");
      // let setting;
      // if (Hardware) {
      //   setting = JSON.parse(Hardware);
      // }
      // 如果存在打印接口
      // if (setting && setting.printTicket) {
      // 选中的业态
      let currentFormat = this.$store.state.common.currentFormat; // 业态：6票务，7游乐，8剧院，11零售，13组合
      // 票务、组合、游乐调用相同的打印硬件方法
      if (
        currentFormat === "6" ||
        currentFormat === "7" ||
        currentFormat === "13"
      ) {
        let api;
        // 票务
        if (currentFormat === "6") {
          api = orderQuickTicketPrint;
        } else if (currentFormat === "13") {
          // 组合产品
          api = orderQuickCombinationPrint;
        }
        api({
          orderId: orderId,
        })
          .then((res) => {
            if (res) {
              let flag = printTicket(JSON.stringify(res));
              //如果成功调用打印门票，就掉接口通知后端已经取票
              if (flag) {
                speak("支付成功");
                orderPrint({
                  channel: 7, //6是窗口端 手持机7
                  orderId: orderId,
                })
                  .then(() => {})
                  .catch(() => {});
              } else {
                speak("支付成功，出票失败");
              }
            }
          })
          .catch(() => {});
      }
      // 零售打印小票
      else if (currentFormat === "11") {
        this.printSmallTicket(orderId);
      }
      // }
    },
    // 打印小票
    printSmallTicket(orderId) {
      let api;
      let currentFormat = this.$store.state.common.currentFormat;
      if (currentFormat === "11") {
        api = orderQuickretailprint;
      }
      api({
        orderId,
      })
        .then((res) => {
          if (res) {
            let flag = printSmallTicket(JSON.stringify(res));
            //如果成功调用打印门票，就掉接口通知后端已经取票
            if (flag) {
              orderPrint({
                channel: 7, //6是窗口端 // 手持机7
                orderId: orderId,
              })
                .then(() => {})
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },
  },
};
