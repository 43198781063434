<template>
  <div>
    <!-- 支付方式 -->
    <div
      class="card-p0"
      :class="{ 'pay-shadow': showSettlementBtn }"
      v-show="showPayWay"
    >
      <p class="font-l bold container">支付方式</p>
      <van-radio-group v-model="payValue">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in payWayList"
            :key="index"
            :title="item.payWayName"
            clickable
            @click="checkPayWay(item)"
          >
            <template #right-icon>
              <van-radio
                :name="item.payWayCode"
                :checked-color="color"
              ></van-radio>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!-- 支付方式弹窗 -->
    <van-dialog
      v-model="isShowPopPayWay"
      title="已下单，请支付"
      show-cancel-button
      theme="round-button"
      :confirm-button-color="color"
      cancel-button-color="#f1f1f1"
      close-on-click-overlay
      @cancel="clear"
      @confirm="confirmPay"
    >
      <div class="pay-way-s">
        <div class="flex-between mb-1x" v-if="refundPrice > 0">
          <p class="title">订单金额</p>
          <p class="title">{{ settlementPrice | money() }}</p>
        </div>
        <div class="flex-between mb-1x" v-if="refundPrice > 0">
          <p class="title">退款金额</p>
          <p class="title">{{ refundPrice | money() }}</p>
        </div>
        <div class="flex-between">
          <p class="title">实付金额</p>
          <p class="red">{{ (settlementPrice - refundPrice) | money() }}</p>
        </div>
        <van-radio-group v-model="payValue">
          <van-radio
            v-for="(item, index) in payWayList"
            :key="index"
            :name="item.payWayCode"
            :checked-color="color"
            class="mt-3x"
          >
            <div class="flex-between">
              <div>{{ item.payWayName }}</div>
              <!-- 识别凭证 -->
              <div
                v-if="item.payWayCode === 'payOnline'"
                class="input-box flex-center ml-1x"
              >
                <input
                  v-model="payCode"
                  type="text"
                  class="input"
                  placeholder="请输入识别凭证"
                />
                <div class="mr-1x">
                  <van-icon
                    name="scan"
                    @click="scan"
                    size="19"
                    color="#B8B8B8"
                  />
                </div>
              </div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
    </van-dialog>
    <!-- 会员信息弹窗 -->
    <van-dialog
      v-model="showMember"
      title="会员信息"
      show-cancel-button
      theme="round-button"
      :confirm-button-color="color"
      cancel-button-color="#f1f1f1"
      close-on-click-overlay
      @cancel="clear"
      @confirm="confirmMemberPay"
    >
      <div class="member-info">
        <div>会员姓名：{{ member.name }}</div>
        <div>手机号：{{ member.mobile }}</div>
        <div class="flex-start">
          <div>支付密码:</div>
          <div class="input-box flex-center ml-1x">
            <input
              type="password"
              v-model="memberPassword"
              maxlength="6"
              class="input"
              placeholder="请输入支付密码"
            />
          </div>
        </div>
      </div>
    </van-dialog>
    <!--等待支付弹窗-->
    <div class="modal" v-show="showWait">
      <div class="center text-c">
        <van-icon :name="loadIcon" size="38" class="loading-icon" />
        <p style="font-size: 16px" class="mt-1x">等待支付</p>
      </div>
    </div>
    <!-- 结算按钮 -->
    <div class="paybtn-box mv-1x" v-if="showSettlementBtn">
      <button
        class="go-pay-btn"
        :style="{
          'background-color': color,
        }"
        @click="goPay"
      >
        立即结算
      </button>
    </div>
    <!-- 会员资产 -->
    <van-dialog
      v-model="showMemberProperty"
      title="会员资产"
      confirm-button-text="取消"
      close-on-click-overlay
    >
      <div class="property mt-1x">
        <div
          class="bt ph-2x pv-2x"
          v-if="memberProperty.memberProductInformationVO.whetherUsable"
        >
          <div class="flex-between">
            <div>
              我的产品:<span
                v-if="!memberProperty.memberProductInformationVO.whetherUsable"
                class="font-s"
                :style="{ color }"
                >（以下产品不可用）</span
              >
            </div>
            <div>
              <button
                class="button check-btn"
                :disabled="
                  !memberProperty.memberProductInformationVO.whetherUsable
                "
                :class="{
                  'disabled-btn':
                    !memberProperty.memberProductInformationVO.whetherUsable,
                }"
                :style="{
                  color,
                  borderColor: color,
                }"
                @click="onClickWriteOffProduct"
              >
                产品核销
              </button>
            </div>
          </div>
          <!-- 不可用列表 -->
          <div v-if="!memberProperty.memberProductInformationVO.whetherUsable">
            <span
              class="font-n"
              v-for="(item, index) in memberProperty.memberProductInformationVO
                .memberProductDeficiencyVOList"
              :key="index"
              >{{ item.productName }} x{{ item.productNum }}
              {{
                memberProperty.memberProductInformationVO
                  .memberProductDeficiencyVOList.length -
                  1 ==
                index
                  ? ""
                  : "，"
              }}
            </span>
          </div>
          <div v-else>
            <van-checkbox-group v-model="productAuxiliaryIdList">
              <van-checkbox
                v-for="(item, index) in memberProperty
                  .memberProductInformationVO.memberAvailableProductVOList"
                :key="index"
                shape="square"
                :name="item.auxiliaryId"
                class="mb-2x"
                >{{ item.productName }}</van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <!-- 暂无接口 -->
        <div class="bt ph-2x pv-2x" v-if="false">
          <div class="flex-between">
            <div>
              兑换券支付:<span
                v-if="
                  !memberProperty.memberAssetCoinCertificateVO.whetherUsable
                "
                class="font-s"
                :style="{ color }"
                >（以下产品不可用）</span
              >
            </div>
            <div>
              <button
                :disabled="
                  !memberProperty.memberAssetCoinCertificateVO.whetherUsable
                "
                class="button check-btn"
                :class="{
                  'disabled-btn':
                    !memberProperty.memberAssetCoinCertificateVO.whetherUsable,
                }"
                :style="{
                  color,
                  borderColor: color,
                }"
                @click="coinCertificatePay"
              >
                兑换支付
              </button>
            </div>
          </div>
          <div>
            <p>产品1</p>
            <p>产品1</p>
          </div>
        </div>

        <div class="bt ph-2x pv-2x">
          <div class="flex-between">
            <div>
              兑换币支付:<span
                class="font-s"
                v-if="!memberProperty.memberConvertibleCurrencyVO.whetherUsable"
                :style="{ color }"
                >（以下产品不可用）</span
              >
            </div>
            <div>
              <button
                :disabled="
                  !memberProperty.memberConvertibleCurrencyVO.whetherUsable
                "
                class="button check-btn"
                :class="{
                  'disabled-btn':
                    !memberProperty.memberConvertibleCurrencyVO.whetherUsable,
                }"
                :style="{
                  color,
                  borderColor: color,
                }"
                @click="onClickCurrencyPay"
              >
                兑换支付
              </button>
            </div>
          </div>
          <div>
            <span
              class="font-n"
              v-for="(item, index) in memberProperty.memberConvertibleCurrencyVO
                .memberProductDeficiencyVOList"
              :key="index"
            >
              {{ item.productName }}（差{{ item.lackMoneyNum / 100 }}元）
            </span>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { merchantuthPayFormWayInfo } from "../api/pay";
import { commonMember, cartCommonDetail } from "../api/cart";
import {
  createQuickOrder,
  payDeductionOrder,
  payOfflineOrder,
  payOncreditOrder,
  tradePaymentInfoMicropayV2,
  tradePaymentInfoStatus,
  productWriteOffStrictBatch,
  payCouponOrder,
} from "../api/order";
import {
  orderOneMemberInfo,
  checkMemberPasswordInfo,
  memberPaymentInformation,
} from "../api/member";
import { scanQRCode, scanCode, clearScanCode } from "../utils/hardware";
import mixins from "./mixins";
import { Dialog } from "vant";
export default {
  name: "PayWay",
  props: {
    // 展示支付方式
    showPayWay: {
      type: Boolean,
      default: true,
    },
    // 展示余额支付
    showMemberBalance: {
      type: Boolean,
      default: true,
    },
    // 展示挂账支付
    showPayAfter: {
      type: Boolean,
      default: true,
    },
    // 购物车id
    cartId: {
      type: Number,
      default: 0,
    },
    // 是否有优惠
    preferential: {
      type: Boolean,
      default: false,
    },
    // 会员手机号
    memberMobile: {
      type: Number,
      default: 0,
    },
    // 业态类型，票务和组合打印用
    businessType: {
      type: String,
      default: "",
    },
    // 是否显示结算按钮
    showSettlementBtn: {
      type: Boolean,
      default: false,
    },
    // 页面路径
    pageType: {
      type: String,
      default: "",
    },
    // 是否订单
    isOrder: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [mixins],

  data() {
    return {
      payValue: "", // 选中的支付方式
      isShowPopPayWay: false, // 是否显示弹窗支付方式
      payWayList: [], // 支付方式列表
      orderId: "", // 订单号
      ownerFormatTypes: [], // 订单包含的业态类型，打印用
      payWayName: "", // 支付名称
      payWayMemberName: "", // 会员支付名称
      loading: false, // 加载中、支付中
      payCode: "", // 支付凭证
      memberPassword: "", // 会员支付密码
      member: {}, // 会员信息
      showMember: false, // 展示会员信息
      isLoginMember: false, // 是否登录会员
      showWait: false, // 等待支付
      loadIcon: require("../assets/images/record/shalou.png"), // 等待支付加载中图标
      settlementPrice: 0, // 订单支付金额
      showMemberProperty: false, //是否显示会员资产弹窗
      memberProperty: {
        memberAssetCoinCertificateVO: {
          consumeRecordList: [],
          memberProductDeficiencyVOList: [],
          whetherUsable: false,
        },
        memberConvertibleCurrencyVO: {
          memberProductDeficiencyVOList: [],
          whetherUsable: false,
        },
        memberProductInformationVO: {
          memberAvailableProductVOList: [],
          memberProductDeficiencyVOList: [],
          productAvailList: [],
          whetherUsable: false,
        },
      }, //会员资产信息
      productAuxiliaryIdList: [],
      cartInfo: [], // 购物车信息
      payMember: false, // 是否支持会员支付
      refundPrice: 0, //退款金额
    };
  },

  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },

  created() {
    this.getPayWay();
  },

  methods: {
    // 立即结算
    goPay() {
      this.pay();
    },
    // 扫码
    scan() {
      scanQRCode(this.scanOk);
    },
    // 扫码成功返回
    scanOk(code) {
      this.payCode = code;
      this.scanEnter();
    },
    // 支付，直接付款调用该方法
    async pay() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) {
          return false;
        }
      }
      this.isShowPopPayWay = true;
      this.memberPassword = ""; // 初始化密码
      this.changeWatchScan();
    },
    // 弹窗是否弹起  弹起就监听扫码
    changeWatchScan() {
      if (this.isShowPopPayWay) {
        scanCode(this.scanOk);
        // try {
        //   window.scanCode = this.scanOk;
        // } catch (error) {
        //   console.log(error);
        // }
      } else {
        // window.scanCode = function () {};
        clearScanCode();
        this.$emit("close", true);
      }
    },
    // 检查会员是否登录
    async checkMem() {
      // 扫会员码支付
      if (/(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8})$/.test(this.payCode)) {
        // 扫码的会员和登录的会员不一致弹提示
        if (this.memberMobile && this.payCode != this.memberMobile) {
          this.$toast("请使用查询到的会员进行支付");
          return false;
        } else if (this.memberMobile) {
          // 有优惠时,已登录会员，没校验过支付密码校验
          if (this.memberPassword) {
            this.memberPay({
              memberId: this.member.id,
              payAccountPwd: this.memberPassword,
            });
          } else {
            return this.checkOrderOneMemberInfo();
          }
        } else {
          // 没登录会员走弹窗输入支付密码
          this.isLoginMember = false;
          return true;
        }
      } else if (this.memberMobile) {
        // 已经登录会员
        return this.checkOrderOneMemberInfo();
      } else {
        // 不是会员码
        return true;
      }
    },
    // 获取会员信息，校验会员支付密码
    checkOrderOneMemberInfo() {
      orderOneMemberInfo({
        evidence: this.memberMobile,
      })
        .then((res) => {
          this.member = res;
          this.showMember = true;
          this.isLoginMember = true;
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    // 下单
    async order() {
      if (!this.cartId) {
        if (this.isOrder && this.orderId) {
          return true;
        } else {
          this.$toast("请选购产品");
          return false;
        }
      } else {
        if (this.orderId) {
          return true;
        } else {
          return createQuickOrder({ shoppingCartId: this.cartId })
            .then((res) => {
              this.orderId = res.orderId;
              this.ownerFormatTypes = res.ownerFormatTypes || [];
              this.settlementPrice = res.settlementPrice; // 订单支付金额
              // 线上支付并且是0元订单就直接支付成功
              if (res.settlementPrice === 0) {
                this.success();
                return false;
              } else {
                return true;
              }
            })
            .catch(() => {
              return false;
            });
        }
      }
    },
    // 确认支付
    confirmPay() {
      // 0元订单直接支付成功
      if (this.settlementPrice === 0) {
        this.success();
        return;
      }
      // 挂账
      if (this.payValue === "payAfter") {
        payOncreditOrder({
          orderId: this.orderId,
        })
          .then(() => {
            this.success();
          })
          .catch(() => {});
      }
      // 线下支付
      else if (this.payValue !== "payOnline") {
        payOfflineOrder({
          orderId: this.orderId,
          payWayCode: this.payValue,
          payWayName: this.payWayName,
        })
          .then(() => {
            this.success();
          })
          .catch(() => {});
      } else if (this.payValue === "payOnline") {
        // 线上支付
        this.scanEnter();
      }
    },
    // 获取支付方式列表
    getPayWay() {
      merchantuthPayFormWayInfo()
        .then((res) => {
          // payFormCategory: 支付形式  1线上支付、2线下支付， 3挂账， 4抵扣
          let payWayList = [];
          let payWayItem = {};
          res.forEach((item) => {
            if (item.payFormCategory === 1) {
              payWayItem = {
                payWayName: "识别凭证",
                payWayCode: "payOnline",
              };
              payWayList.push(payWayItem);
            } else if (item.payFormCategory === 2) {
              item.authPayWayVOS.forEach((val) => {
                payWayItem = {
                  payWayName: val.payWayName,
                  payWayCode: val.payWayId,
                };
                payWayList.push(payWayItem);
              });
            } else if (item.payFormCategory === 3 && this.showPayAfter) {
              payWayItem = {
                payWayName: "挂账",
                payWayCode: "payAfter",
              };
              payWayList.push(payWayItem);
            } else if (item.payFormCategory === 4 && this.showMemberBalance) {
              const temp = item.authPayWayVOS.find(
                (temp) => temp.payWayCode === "MemberBalance"
              );
              if (temp) {
                // payWayItem = {
                //   payWayName: temp.payWayName,
                //   payWayCode: temp.payWayCode,
                // };
                // payWayList.push(payWayItem);
                this.payMember = !!temp;
                this.payWayMemberName = temp.payWayName;
              }
            }
          });
          this.payWayList = payWayList;
        })
        .catch(() => {});
    },
    // 选择支付方式
    checkPayWay(item) {
      this.payValue = item.payWayCode;
      this.changePayWay();
    },
    // 选择弹窗支付方式
    async changePayWay() {
      // 选择挂账没有登录会员则需要先登录会员才能挂账
      if (this.payValue === "payAfter" && !this.memberMobile) {
        this.$toast("请先登录会员后挂账");
        this.$nextTick(() => {
          // 选回识别凭证
          this.payValue = "payOnline";
        });
        return false;
      }
      if (this.preferential) {
        if (this.memberPassword) {
          this.payOrder();
        } else {
          // 是否登录会员
          const pwdOk = await this.checkMem();
          if (pwdOk) {
            this.createOrder();
          }
        }
      } else {
        this.createOrder();
      }
    },
    // 选择支付方式后下单
    async createOrder() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) {
          this.payValue = "payOnline";
          return false;
        }
      }
      if (this.payValue !== "payOnline") {
        this.isShowPopPayWay = true;
        this.memberPassword = ""; // 初始化密码
        this.changeWatchScan();
        // 线下非挂账
        if (this.payValue !== "payAfter") {
          const temp = this.payWayList.find(
            (temp) => temp.payWayCode === this.payValue
          );
          this.payWayName = temp.payWayName;
        }
      } else {
        this.payOrder();
      }
    },
    // 扫码回车
    async scanEnter() {
      if (!this.payCode) {
        this.$toast.fail("请输入/扫描凭证");
        return;
      }
      this.memberPassword = ""; // 初始化支付密码  防止切换会员了 不校验支付密码直接弹窗会员资产
      // 是否有优惠
      if (this.preferential) {
        const checkMemOk = await this.checkMem();
        if (checkMemOk) this.payOrder();
      } else {
        this.payOrder();
      }
    },
    // 下单支付
    async payOrder() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) return;
      }
      if (!this.payCode) {
        return;
      }
      // 会员码支付
      if (/\d{12}D\d{4}/.test(this.payCode)) {
        // 后台未开启会员余额支付
        if (!this.showMemberBalance || !this.payMember) {
          this.$toast.fail("不支持的凭证！");
          return;
        }
        // 会员码就直接弹会员资产
        const memberInfoOk = await this.getOrderOneMemberInfo();
        if (memberInfoOk) {
          this.showMember = false;
          this.getMemberProperty();
        }
        // this.memberPay({ memberCode: this.payCode });
      } else if (/(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8})$/.test(this.payCode)) {
        // 后台未开启会员余额支付
        if (!this.showMemberBalance || !this.payMember) {
          this.$toast.fail("不支持的凭证！");
          return;
        }
        // 有支付密码就直接调会员支付
        if (this.memberPassword) {
          this.showMember = false;
          this.getMemberProperty();
        } else {
          // 没有会员支付密码就弹窗输入
          const memberInfoOk = await this.getOrderOneMemberInfo();
          if (memberInfoOk) {
            this.showMember = true;
          }
        }
      }
      // 线上支付
      else {
        this.onlinePay();
      }
    },
    // 获取会员信息
    async getOrderOneMemberInfo() {
      let ret = false;
      await orderOneMemberInfo({
        evidence: this.payCode,
      })
        .then((res) => {
          this.isShowPopPayWay = false;
          this.member = res;
          this.changeWatchScan();
          ret = true;
        })
        .catch(() => {});
      return ret;
    },
    // 会员支付确认
    async confirmMemberPay() {
      if (!this.memberPassword) {
        this.$toast.fail("请输入支付密码");
        return;
      } else if (isNaN(this.memberPassword) || this.memberPassword.length < 6) {
        this.$toast.fail("请输入正确的密码");
        return;
      }
      let isOk = await this.checkMemberPassword();
      if (!isOk) {
        this.memberPassword = "";
        return;
      }
      // 登录会员时扫会员码校验会员支付密码
      if (this.isLoginMember) {
        commonMember({
          cartId: this.cartId,
          memberPaymentPassword: this.memberPassword,
        }).then(() => {
          this.showMember = false;
          this.createOrder();
        });
      } else {
        this.getMemberProperty();

        // this.memberPay({
        //   memberId: this.member.id,
        //   payAccountPwd: this.memberPassword,
        // });
      }
    },
    // 会员支付
    memberPay(data) {
      const obj = {
        orderId: this.orderId,
        payWayCode: "MemberBalance",
        payWayName: this.payWayMemberName,
      };
      const item = Object.assign(obj, data);
      payDeductionOrder(item)
        .then(() => {
          this.success();
        })
        .catch(() => {});
    },
    // 线上支付
    onlinePay() {
      if (this.loading) return;
      this.loading = true;
      this.isShowPopPayWay = false;
      const data = {
        orderNo: this.orderId,
        authCode: this.payCode,
      };
      tradePaymentInfoMicropayV2(data, { loading: false })
        .then((res) => {
          // 等待支付
          if (res === "PAYING" || res === "NOT_PAY") {
            this.getStatusLoop();
            this.showWait = true;
          }
          // 支付失败
          else if (res === "PAY_FAIL") {
            this.$toast.fail("支付失败，请重新支付");
            this.loading = false;
          } // 支付成功 除了以上状态都可认为支付成功 res === "PAID"
          else {
            this.success();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 轮询支付状态
    getStatusLoop() {
      if (this.loopTimer) {
        clearTimeout(this.loopTimer);
      }
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus({ orderNo: this.orderId }, { loading: false })
          .then((res) => {
            if (res === "PAYING" || res === "NOT_PAY") {
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              this.$toast("支付失败，请重新支付");
              this.showWait = false;
              this.payCode = "";
              this.loading = false;
            } else {
              // 支付成功 除了以上状态都可认为支付成功 res === "PAID"
              this.success();
              this.loading = false;
            }
          })
          .catch(() => {
            this.showWait = false;
            this.payCode = "";
            this.loading = false;
          });
      }, 2000);
    },
    //单独校检用户密码是否正确
    async checkMemberPassword() {
      let isPass = false;
      await checkMemberPasswordInfo({
        memberId: this.member.id,
        password: this.memberPassword,
      })
        .then((resp) => {
          if (!resp) {
            isPass = true;
          }
        })
        .catch(() => {
          isPass = false;
        });
      return isPass;
    },
    //根据会员信息、购物车信息获取会员资产信息
    async getMemberProperty() {
      const cartArr = await this.getCartInfo(this.cartId); // 获取购物车产品id
      memberPaymentInformation({
        memberId: this.member.id,
        cartId: this.cartId,
      }).then((resp) => {
        let nameMap = new Map();
        let countMap = new Map();
        resp?.memberProductInformationVO?.memberAvailableProductVOList?.map(
          (item) => {
            if (!nameMap.has(item.productId)) {
              nameMap.set(item.productId, item.productName);
            }

            if (!countMap.has(item.productId)) {
              countMap.set(item.productId, 0);
            }
            countMap.set(item.productId, countMap.get(item.productId) + 1);
          }
        );

        let productArr = Array.from(countMap, ([productId, count]) => {
          return {
            productId,
            count,
            productName: nameMap.get(productId) || "",
          };
        });

        productArr = productArr || [];

        if (resp.memberProductInformationVO) {
          resp.memberProductInformationVO.productAvailList = productArr;
        }
        // 默认勾选可兑换产品
        if (resp.memberProductInformationVO.whetherUsable) {
          let productAuxiliaryIdList = [];
          if (resp.memberProductInformationVO.memberAvailableProductVOList) {
            resp.memberProductInformationVO.memberAvailableProductVOList.forEach(
              (val) => {
                cartArr.find((cartItem) => {
                  // 产品id相等并且购物车数组的数量大于0就push到勾选中，购物车数组数量减一
                  if (
                    cartItem.productId === val.productId &&
                    cartItem.buyNum > 0
                  ) {
                    productAuxiliaryIdList.push(val.auxiliaryId);
                    cartItem.buyNum--;
                  }
                });
              }
            );
          }

          // 没有可选中产品时
          if (productAuxiliaryIdList.length === 0) {
            resp.memberProductInformationVO.whetherUsable = false;
          }
          this.productAuxiliaryIdList = productAuxiliaryIdList; // 选中的产品
        }
        this.memberProperty = resp;
        this.checkConversion(resp);
      });
    },
    // 是否直接调用兑换支付
    checkConversion(resp) {
      // 只有兑奖券可用时
      if (
        resp.memberAssetCoinCertificateVO.whetherUsable &&
        !resp.memberConvertibleCurrencyVO.whetherUsable &&
        !resp.memberProductInformationVO.whetherUsable
      ) {
        console.log("兑奖券");
        this.coinCertificatePay();
      } else if (
        !resp.memberAssetCoinCertificateVO.whetherUsable &&
        resp.memberConvertibleCurrencyVO.whetherUsable &&
        !resp.memberProductInformationVO.whetherUsable
      ) {
        // 只有兑换币可用时,直接调兑换币支付
        this.onClickCurrencyPay();
      } else if (
        !resp.memberAssetCoinCertificateVO.whetherUsable &&
        !resp.memberConvertibleCurrencyVO.whetherUsable &&
        resp.memberProductInformationVO.whetherUsable &&
        resp.memberProductInformationVO.memberAvailableProductVOList.length ===
          1
      ) {
        // 只有产品兑换且只有一个产品时就直接调产品核销
        this.sendWriteOffProduct(this.productAuxiliaryIdList);
      } else {
        this.showMemberProperty = true;
      }
    },
    //获取购物车详情
    async getCartInfo(cartId) {
      let arr = [];
      await cartCommonDetail({
        cartId: cartId || this.cartId,
      })
        .then((res) => {
          let productIdArr = []; // 获取购物车里产品详情
          if (res.detailProductList.length) {
            res.detailProductList.forEach((item) => {
              let arr = {
                buyNum: item.productFlagPO.buyNum,
                productId: item.productId,
              };
              productIdArr.push(arr);
            });
          }
          arr = productIdArr;
          this.cartInfo = arr;
        })
        .catch(() => {});
      return arr;
    },
    // 取消
    clear() {
      this.payValue = "payOnline";
      this.isShowPopPayWay = false;
      this.showMemberProperty = false;
      this.changeWatchScan();
      this.showMember = false;
      this.showWait = false;
      if (!this.isOrder) {
        this.orderId = "";
      }

      this.payCode = "";
      this.memberPassword = "";
      this.loading = false;
    },
    // 支付成功
    success() {
      this.$toast.success("支付成功");
      this.$store.commit("common/customPrintType", null);
      // 调打印和语音播报
      // this.payOk(this.ownerFormatTypes, this.orderId);
      // 门票跟组合暂时通过父组件传进业态区分打印， 后续应该跟华哥后台约定
      this.payOk(this.orderId, false, this.pageType);
      this.clear();
      this.$emit("success", true);
    },
    // 产品核销
    onClickWriteOffProduct() {
      if (!this.productAuxiliaryIdList.length) {
        this.$toast("请选择要核销的产品");
        return;
      }
      if (this.cartInfo.length !== this.productAuxiliaryIdList.length) {
        this.$toast("核销产品数和购物车产品数不一致");
        return;
      }
      Dialog.alert({
        title: "提示",
        message: "确定要执行核销操作吗",
        showCancelButton: true,
      })
        .then(() => {
          this.sendWriteOffProduct(this.productAuxiliaryIdList);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 产品核销
    sendWriteOffProduct(codeList) {
      productWriteOffStrictBatch({
        codeList: codeList,
      }).then((res) => {
        if (res.code === 500) {
          this.$toast(res.otherMsg ? res.otherMsg : "核销失败！");
        } else {
          this.$toast(res.otherMsg ? res.otherMsg : "核销成功！");

          this.success();
        }
      });
    },
    // 兑换券支付
    coinCertificatePay() {
      Dialog.alert({
        title: "提示",
        message: "确定要执行兑换操作吗?",
        showCancelButton: true,
      })
        .then(() => {
          payCouponOrder({
            orderId: this.orderId,
            memberId: this.member.id,
          })
            .then(() => {
              this.success();
            })
            .catch(() => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    //兑换币支付
    onClickCurrencyPay() {
      Dialog.alert({
        title: "提示",
        message: "确定要执行兑换操作吗?",
        showCancelButton: true,
      })
        .then(() => {
          if (/\d{12}D\d{4}/.test(this.payCode)) {
            // 动态二维码就直接传动态码
            this.memberPay({ memberCode: this.payCode });
          } else {
            // 手机号要校验会员密码
            this.memberPay({
              memberId: this.member.id,
              payAccountPwd: this.memberPassword,
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped lang="less">
/*支付弹窗样式*/
.pay-way-s {
  max-height: 70vh;
  padding: 16px;
  overflow-y: auto;
  .input-box {
    width: 180px;
    height: 34px;
    background: #f3f3f3;
    border-radius: 6px;
  }
}
.input {
  background: #f3f3f3;
  border: none;
  width: calc(100% - 39px);
  font-size: 14px;
  padding-left: 16px;
  padding-right: 10px;
  height: 34px;
}
.member-info {
  padding: 8px 17px 20px;
  line-height: 40px;
  font-size: 14px;
}
/*确认订单弹窗*/
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  background: rgba(12, 12, 12, 0.42);
  .center {
    width: 300px;
    padding: 40px 24px;
    border-radius: 17px;
    background: #fff;
    z-index: 3;
    .modal-radio {
      display: block;
      height: 40px;
      margin-bottom: 12px;
      line-height: 40px;
    }
  }
}
.pay-shadow {
  box-shadow: 0 0 2px 2px rgba(75, 75, 75, 0.1);
  border-radius: 6px;
}
// 结算按钮
.paybtn-box {
  .go-pay-btn {
    width: 100%;
    color: #fff;
    padding: 10px 0px;
    border: none;
    border-radius: 25px;
  }
}
// 会员资产
.property {
  max-height: 70vh;
  min-height: 50vh;
  overflow: auto;
  .bt {
    border-top: 1px solid #f1f1f1;
    .button {
      color: #b8b8b8;
      width: 80px;
      height: 32px;
      border-radius: 18px;
      border: 1px solid #b8b8b8;
      background-color: #fff;
      font-size: 12px;
    }
    .disabled-btn {
      color: #b8b8b8 !important;
      border-color: #b8b8b8 !important;
    }
  }
}
.loading-icon {
  animation: an1 3s infinite;
}
@keyframes an1 {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(180deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  75% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.title {
  color: #666;
}
</style>
